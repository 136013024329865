export interface TranslateFieldItem {
    description: string
    placeId: string
    translatedLanguage?: string
    originalDescription?: string
}

export async function translateGooglePlaceFields(
    fields: TranslateFieldItem[],
    language: string
): Promise<TranslateFieldItem[]> {
    if (!fields.length || !(window as any)?.google?.maps?.places) {
        return fields
    }

    return new Promise((resolve) => {
        const promises = fields.map((fieldItem) => {
            if (fieldItem.translatedLanguage === language) {
                return Promise.resolve(fieldItem) // Skip already translated
            }
            return new Promise<TranslateFieldItem>((innerResolve) => {
                const request = {
                    placeId: fieldItem.placeId,
                    fields: ["name", "formatted_address", "address_components"],
                    language,
                }
                new (window as any).google.maps.places.PlacesService(document.createElement("div")).getDetails(
                    request,
                    (result, status) => {
                        if (status === "OK" && result) {
                            const components = result.address_components
                            console.log(components)
                            // Récupérer les parties importantes de l'adresse
                            const locality =
                                components.find((c) => c.types.includes("locality"))?.long_name ||
                                components.find((c) => c.types.includes("administrative_area_level_2"))?.long_name ||
                                ""
                            const region =
                                language === "en"
                                    ? components.find((c) => c.types.includes("administrative_area_level_1"))
                                          ?.short_name || ""
                                    : components.find((c) => c.types.includes("administrative_area_level_1"))
                                          ?.long_name || ""
                            const country = components.find((c) => c.types.includes("country"))?.long_name || ""
                            const customAddress = [locality, region, country].filter((part) => part).join(", ")
                            innerResolve({
                                ...fieldItem,
                                description: customAddress,
                            })
                        } else {
                            innerResolve(fieldItem) // Return original if translation fails
                        }
                    }
                )
            })
        })

        Promise.all(promises)
            .then((translatedFields: TranslateFieldItem[]) => resolve(translatedFields))
            .catch((error) => {
                console.error("Error translating Google Place fields:", error)
                resolve(fields) // Return original fields in case of error
            })
    })
}
